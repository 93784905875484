import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import LinkUi from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { IconButton, Theme, Tooltip } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PageLoader from "../Loaders/PageLoader";
import TableComponent from "../Table/TableComponent";
import { formatToDate } from "../../Utils/MomentUtils";
import CloseStockHistoryModal from "../StockHistory/CloseStockHistoryModal";
import { IStockOpening } from "types/stock.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noStockOpening: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface IProductStockOpeningProps {
  loading?: boolean;
  stockOpenings: Array<IStockOpening>;
  callbackCloseStockOpening: () => void;
}

const ProductStockOpening = (props: IProductStockOpeningProps): JSX.Element => {
  const { loading = false, stockOpenings, callbackCloseStockOpening } = props;

  const { classes } = useStyles();

  const [isOpenCloseStockHistoryModal, setIsOpenCloseStockHistoryModal] =
    useState<IStockOpening | undefined>(undefined);
  const [items, setItems] = useState<Array<Array<JSX.Element>>>([]);

  useEffect(() => {
    const newItems: Array<Array<JSX.Element>> = [];
    stockOpenings.forEach((elt) => {
      const { openingDate, closingDate } = elt;
      newItems.push([
        <>{formatToDate(openingDate)}</>,
        <>{closingDate ? formatToDate(closingDate) : "-"}</>,
        <>
          <LinkUi to={`/warehouses/${elt.warehouse.id}`} component={Link}>
            {elt.warehouse.name}
          </LinkUi>
        </>,
        <>
          <LinkUi
            to={`/storage-places/${elt.storagePlace.id}`}
            component={Link}
          >
            {elt.storagePlace.name}
          </LinkUi>
        </>,
        <>
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setIsOpenCloseStockHistoryModal(elt)}
            disabled={!!closingDate}
          >
            <Tooltip title="Fermer le stock" placement="top">
              <HighlightOffIcon />
            </Tooltip>
          </IconButton>
        </>,
      ]);
    });
    setItems(newItems);
  }, [stockOpenings]);

  if (!loading && stockOpenings.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Stocks ouverts
        </Typography>
        <div className={classes.noStockOpening}>
          <div>Aucun produit en cours d&apos;ouverture.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <CloseStockHistoryModal
            open={!!isOpenCloseStockHistoryModal}
            stockOpening={isOpenCloseStockHistoryModal}
            handleClose={() => {
              setIsOpenCloseStockHistoryModal(undefined);
              callbackCloseStockOpening();
            }}
          />
          <Typography component="h1" variant="h5">
            Stocks ouverts
          </Typography>
          <TableComponent
            columnsName={[
              "Date d'ouverture",
              "Date de fermeture",
              "Entrepôt",
              "Lieu de stockage",
              "Actions",
            ]}
            items={items}
          />
        </>
      )}
    </Paper>
  );
};

export default ProductStockOpening;
