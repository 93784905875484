import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import {
  CssBaseline,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";
import ProductIcon from "@mui/icons-material/Store";
import WarehouseIcon from "@mui/icons-material/Storage";

import Header from "./Header";
import { CSSObject } from "tss-react/types";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  } as CSSObject,
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  } as CSSObject,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;

  const location = useLocation();
  const { pathname } = location;
  const { classes } = useStyles();

  const [openMenu, setOpenMenu] = useState<boolean>(
    localStorage.getItem("MENU_OPEN") === "true",
  );
  const [openProductMenu, setOpenProductMenu] = useState<boolean>(
    localStorage.getItem("MENU_OPEN_PRODUCT") === "true",
  );
  const [openWarehouseMenu, setOpenWarehouseMenu] = useState<boolean>(
    localStorage.getItem("MENU_OPEN_WAREHOUSE") === "true",
  );
  const [openStoragePlaceMenu, setOpenStoragePlaceMenu] = useState<boolean>(
    localStorage.getItem("MENU_OPEN_STORAGE_PLACE") === "true",
  );

  const handleChangeOpenMenu = () => {
    localStorage.setItem("MENU_OPEN", !openMenu === true ? "true" : "false");
    setOpenMenu(!openMenu);
  };

  const handleChangeOpenProductMenu = () => {
    localStorage.setItem(
      "MENU_OPEN_PRODUCT",
      !openProductMenu === true ? "true" : "false",
    );
    setOpenProductMenu(!openProductMenu);
  };

  const handleChangeOpenWarehouseMenu = () => {
    localStorage.setItem(
      "MENU_OPEN_WAREHOUSE",
      !openWarehouseMenu === true ? "true" : "false",
    );
    setOpenWarehouseMenu(!openWarehouseMenu);
  };

  const handleChangeOpenStoragePlaceMenu = () => {
    localStorage.setItem(
      "MENU_OPEN_STORAGE_PLACE",
      !openStoragePlaceMenu === true ? "true" : "false",
    );
    setOpenStoragePlaceMenu(!openStoragePlaceMenu);
  };

  useEffect(() => {
    if (pathname === "/products" || pathname === "/new-product") {
      localStorage.setItem("MENU_OPEN_PRODUCT", "true");
      setOpenProductMenu(true);
    }
    if (pathname === "/warehouses" || pathname === "/new-warehouse") {
      localStorage.setItem("MENU_OPEN_WAREHOUSE", "true");
      setOpenWarehouseMenu(true);
    }
    if (pathname === "/storage-places" || pathname === "/new-storage-place") {
      localStorage.setItem("MENU_OPEN_STORAGE_PLACE", "true");
      setOpenStoragePlaceMenu(true);
    }
  }, [pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={openMenu} setMenuOpen={handleChangeOpenMenu} />
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !openMenu && classes.drawerPaperClose,
          ),
        }}
        open={openMenu}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleChangeOpenMenu}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          <Link to="/">
            <ListItem button selected={pathname === "/"}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Accueil" />
            </ListItem>
          </Link>
          <ListItem
            button
            onClick={handleChangeOpenProductMenu}
            data-testid="menu-products"
          >
            <ListItemIcon>
              <ProductIcon />
            </ListItemIcon>
            <ListItemText primary="Produits" />
            {openProductMenu === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openProductMenu} timeout="auto" unmountOnExit>
            <Link to="/products" data-testid="menu-products-home">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/products"}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Produits" />
              </ListItem>
            </Link>
            <Link to="/new-product" data-testid="menu-products-new">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/new-product"}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Ajouter un produit" />
              </ListItem>
            </Link>
          </Collapse>
          <ListItem
            button
            onClick={handleChangeOpenWarehouseMenu}
            data-testid="menu-warehouses"
          >
            <ListItemIcon>
              <WarehouseIcon />
            </ListItemIcon>
            <ListItemText primary="Entrepôts" />
            {openWarehouseMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openWarehouseMenu} timeout="auto" unmountOnExit>
            <Link to="/warehouses" data-testid="menu-warehouses-home">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/warehouses"}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Entrepôts" />
              </ListItem>
            </Link>
            <Link to="/new-warehouse" data-testid="menu-warehouses-new">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/new-warehouse"}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Ajouter un entrepôt" />
              </ListItem>
            </Link>
          </Collapse>
          <ListItem
            button
            onClick={handleChangeOpenStoragePlaceMenu}
            data-testid="menu-storage-places"
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Lieux" />
            {openStoragePlaceMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={openStoragePlaceMenu} timeout="auto" unmountOnExit>
            <Link to="/storage-places" data-testid="menu-storage-places-home">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/storage-places"}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Lieux" />
              </ListItem>
            </Link>
            <Link to="/new-storage-place" data-testid="menu-storage-places-new">
              <ListItem
                button
                className={classes.nested}
                selected={pathname === "/new-storage-place"}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Ajouter un lieu" />
              </ListItem>
            </Link>
          </Collapse>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
