import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "moment/locale/fr";
import Home from "routes/Home";
import HomeProducts from "routes/products/HomeProducts";
import CreateProduct from "routes/products/CreateProduct";
import ProductDetail from "routes/products/ProductDetail";
import HomeWarehouses from "routes/warehouses/HomeWarehouses";
import CreateWarehouse from "routes/warehouses/CreateWarehouse";
import WarehouseDetail from "routes/warehouses/WarehouseDetail";
import HomeStoragePlaces from "routes/storage-places/HomeStoragePlaces";
import CreateStoragePlace from "routes/storage-places/CreateStoragePlace";
import StoragePlaceDetail from "routes/storage-places/StoragePlaceDetail";
import { initKeycloak } from "services/KeycloakService";

const Router = () => {
  const [initKC, setInitKC] = useState(false);
  const [loadingKC, setLoadingKC] = useState(false);

  useEffect(() => {
    if (!initKC && !loadingKC) {
      setLoadingKC(true);
      initKeycloak().then(() => {
        setInitKC(true);
        setLoadingKC(false);
      });
    }
  }, [initKC, loadingKC]);

  if (loadingKC || !initKC) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/products"
          element={<HomeProducts random={new Date().getTime()} />}
        />
        <Route path="/new-product" element={<CreateProduct />} />
        <Route path="/products/:productId" element={<ProductDetail />} />
        <Route
          path="/warehouses"
          element={<HomeWarehouses random={new Date().getTime()} />}
        />
        <Route path="/new-warehouse" element={<CreateWarehouse />} />
        <Route path="/warehouses/:warehouseId" element={<WarehouseDetail />} />
        <Route
          path="/storage-places"
          element={<HomeStoragePlaces random={new Date().getTime()} />}
        />
        <Route path="/new-storage-place" element={<CreateStoragePlace />} />
        <Route
          path="/storage-places/:storagePlaceId"
          element={<StoragePlaceDetail />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
