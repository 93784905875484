import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateProduct from "../../components/Products/CreateOrUpdateProduct";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import ProductAddStoragePlace from "components/Products/ProductAddStoragePlace";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import ProductStockOpening from "../../components/Products/ProductStockOpening";
import {
  useDeleteProduct,
  useProduct,
  useProductsHistory,
} from "../../Hooks/products.hook";
import { useWarehouses } from "../../Hooks/warehouses.hook";
import useStockOpenings from "../../Hooks/stockOpening.hook";
import { AxiosError, AxiosResponse } from "axios";
import { IProduct } from "types/product.type";
import ProductStoragePlaces from "components/Products/ProductStoragePlaces";

const ProductDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { productId = "" } = useParams<{
    productId: IProduct["id"];
  }>();
  const navigate = useNavigate();

  const [showConfirmDeleteProduct, setShowConfirmDeleteProduct] =
    useState(false);

  const {
    product,
    isFetching: isFetchingProduct,
    isError: isErrorProduct,
    error: errorProduct,
  } = useProduct({
    productId,
    join: "storagePlaces",
  });

  const {
    productsHistory,
    isFetching: isFetchingProductsHistory,
    isError: isErrorProductsHistory,
  } = useProductsHistory({
    productId,
  });

  const {
    stockOpenings,
    isFetching: isFetchingStockOpenings,
    isError: isErrorStockOpenings,
    refetch: refetchStockOpenings,
  } = useStockOpenings({
    join: "storagePlace,warehouse",
    filter: `productId||eq||${productId}`,
    sort: "openingDate,ASC",
  });

  const { mutate: deleteProduct, isPending: isPendingDeleteProduct } =
    useDeleteProduct({
      onSuccess: () => {
        enqueueSnackbar(`Le produit "${product.name}" a été supprimé.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate("/products");
      },
      onError: ({ response }) => {
        const { data, status } = response as AxiosResponse;

        enqueueSnackbar(
          status === 403 &&
            data.title &&
            data.title === "PRODUCT_HAVE_QUANTITY_FOR_A_LINKED_STORAGE_PLACE"
            ? `Le produit "${product.name}" ne peut pas être supprimé car un lieu de stockage contient du stock.`
            : `Une erreur est survenue lors de la suppression du produit "${product.name}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        setShowConfirmDeleteProduct(!showConfirmDeleteProduct);
      },
    });

  const {
    warehouses,
    isFetching: isFetchingWarehouses,
    isError: isErrorWarehouses,
  } = useWarehouses({ join: "storagePlaces" });

  useEffect(() => {
    if (isErrorProduct) {
      const productNotFound =
        (errorProduct as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        productNotFound
          ? `Le produit "${productId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération du produit.",
        {
          variant: productNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/products");
    }
  }, [enqueueSnackbar, isErrorProduct, navigate, productId]);

  useEffect(() => {
    if (isErrorProductsHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique du produit.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorProductsHistory, navigate]);

  useEffect(() => {
    if (isErrorWarehouses) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorWarehouses]);

  useEffect(() => {
    if (isErrorStockOpenings) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des stocks ouverts.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorStockOpenings]);

  const handleChangeConfirmDeleteProduct = () => {
    if (!isPendingDeleteProduct) {
      setShowConfirmDeleteProduct(!showConfirmDeleteProduct);
    }
  };

  return (
    <MainLayout>
      {showConfirmDeleteProduct && (
        <ConfirmationModal
          key="delete-product-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeleteProduct}
          title="Suppression d'un produit."
          description={`Voulez-vous vraiment supprimer le produit "${product.name}" ?`}
          handleClose={handleChangeConfirmDeleteProduct}
          yesCallback={() => deleteProduct({ productId })}
        />
      )}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} xl={8} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeleteProduct}
            variant="contained"
            color="secondary"
            disabled={isFetchingProduct}
          >
            Supprimer
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdateProduct
                productUpdate={product}
                title={
                  product && product.name
                    ? `Mise à jour de "${product.name}"`
                    : ""
                }
                loading={isFetchingProduct}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ProductAddStoragePlace
                loading={isFetchingWarehouses}
                product={product}
                warehouses={warehouses}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} xl={8}>
              <ProductStockOpening
                loading={isFetchingStockOpenings}
                stockOpenings={stockOpenings}
                callbackCloseStockOpening={() => refetchStockOpenings()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} xl={8}>
              <ProductStoragePlaces
                productId={productId}
                productHasOpeningDate={product.hasOpeningDate}
                loading={isFetchingWarehouses || isFetchingProduct}
                storagePlaces={product.storagePlaces}
                warehouses={warehouses}
                callbackAddUseStock={() => refetchStockOpenings()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} xl={8}>
              <ResourceHistory
                history={productsHistory}
                loading={isFetchingProductsHistory}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ProductDetail;
