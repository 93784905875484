import axios from "axios";
import config from "../config";
import { IProduct } from "types/product.type";
import { TFilter, TJoin } from "types/query.type";
import { IStoragePlace } from "src/types/storage-place.type";

export const getProducts = (join: TJoin, filter: TFilter) =>
  axios.get(`${config.API.HOST}/products`, {
    params: {
      join,
      filter,
    },
  });

export const getProduct = (productId: IProduct["id"], join: TJoin) =>
  axios.get(`${config.API.HOST}/products/${productId}`, {
    params: {
      join,
    },
  });

export const postProduct = (product: FormData) =>
  axios.post(`${config.API.HOST}/products`, product);

export const patchProduct = (
  productId: IProduct["id"],
  productFormData: FormData,
) => axios.patch(`${config.API.HOST}/products/${productId}`, productFormData);

export const getProductPicture = (productId: IProduct["id"]) =>
  axios.get(`${config.API.HOST}/products/${productId}/picture`, {
    responseType: "blob",
  });

export const deleteProduct = (productId: IProduct["id"]) =>
  axios.delete(`${config.API.HOST}/products/${productId}`);

export const getProductsHistory = (productId: IProduct["id"]) =>
  axios.get(`${config.API.HOST}/actionshistory`, {
    params: {
      join: "product,storagePlace,warehouse",
      filter: [`productId||eq||${productId}`, "sourceType||eq||PRODUCT"],
      sort: "createdAt,ASC",
    },
  });

export const addProductToAStoragePlace = (
  productId: IProduct["id"],
  storagePlaceId: IStoragePlace["id"],
) =>
  axios.post(`${config.API.HOST}/products/${productId}/linked`, {
    storagePlaceId,
  });
