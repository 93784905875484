import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { makeStyles } from "tss-react/mui";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
// @ts-ignore
import Pluralize from "react-pluralize";
import PageLoader from "../Loaders/PageLoader";
import WarehouseImage from "../Warehouses/WarehouseImage";
import StoragePlaceImage from "../StoragePlaces/StoragePlaceImage";
import ConfirmationModalWithFormik from "../Modals/ConfirmationModalWithFormik";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import StockHistoryModal from "../StockHistory/StockHistoryModal";
import {
  useAddStockToAProduct,
  useStocksQuantityForProduct,
  useUseStockForProductAndStoragePlace,
} from "../../Hooks/stock.hook";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IStockQuantity } from "types/stock.type";
import { IStoragePlace } from "src/types/storage-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  displayStoragePlacePaperContainer: {
    minHeight: "100%",
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  warehouseImageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    margin: "auto",
  },
  warehouseImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  warehouseTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
    margin: "auto",
  },
  warehouseTitleContainer: {
    display: "flex",
  },
  storagePlacesOnWarehouseContainer: {
    wordBreak: "break-word",
    textAlign: "right",
    margin: "auto",
    width: "auto",
  },
  storagePlaceImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  storagePlaceImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  storagePlaceTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
  storagePlaceFooterContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
  },
  linkGoToWarehouseOrStoragePlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  displayStoragePlaceContainer: {
    margin: theme.spacing(2, 0),
  },
  badgeRoot: {
    display: "inherit",
    cursor: "pointer",
  },
  paperStoragePlace: {
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Lieux de stockage
    </Typography>
  );
};

interface IProductStoragePlacesProps {
  productId: IProduct["id"];
  productHasOpeningDate: boolean;
  loading?: boolean;
  warehouses: Array<IWarehouse>;
  storagePlaces: Array<IStoragePlace>;
  callbackAddUseStock: () => void;
}

const ProductStoragePlaces = (props: IProductStoragePlacesProps) => {
  const {
    productId,
    productHasOpeningDate = null,
    loading = false,
    warehouses,
    storagePlaces = [],
    callbackAddUseStock,
  } = props;

  type TDisplayStoragePlaces = Array<{
    id: IWarehouse["id"];
    name: IWarehouse["name"];
    storagePlaces: Array<IStoragePlace>;
    warehouse?: Array<IWarehouse>;
  }>;

  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [displayAddStockModal, setDisplayAddStockModal] =
    useState<IStoragePlace>();
  const [displayUseStockModal, setDisplayUseStockModal] =
    useState<IStoragePlace>();
  const [isOpenModalChooseUseDate, setIsOpenModalChooseUseDate] =
    useState(false);
  const [isOpenStockQuantityModal, setIsOpenStockQuantityModal] =
    useState(false);
  const [
    storagePlaceForStockQuantityModal,
    setStoragePlaceForStockQuantityModal,
  ] = useState<IStoragePlace | undefined>();
  const [warehouseForStockQuantityModal, setWarehouseForStockQuantityModal] =
    useState<IWarehouse | undefined>();
  const [displayStoragePlaces, setDisplayStoragePlaces] =
    useState<TDisplayStoragePlaces>([]);

  const {
    stocksQuantityForProduct,
    isFetching: isFetchingStocksQuantityForProduct,
    isError: isErrorStocksQuantityForProduct,
    refetch: refetchStocksQuantityForProduct,
  } = useStocksQuantityForProduct({
    productId,
  });

  useEffect(() => {
    if (isErrorStocksQuantityForProduct) {
      enqueueSnackbar(
        `Une erreur est survenue lors de la récupération de la quantité des stocks.`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isErrorStocksQuantityForProduct]);

  useEffect(() => {
    if (!loading) {
      const newWarehousesToProduct: Array<IWarehouse> = warehouses.filter(
        (warehouse) =>
          storagePlaces.find((linked) => linked.warehouseId === warehouse.id),
      );

      const newDisplayStoragePlaces: TDisplayStoragePlaces = [];
      newWarehousesToProduct.forEach((newWarehouseToProduct) => {
        const storagePlacesOnWarehouse = storagePlaces.filter(
          (storagePlace) =>
            storagePlace.warehouseId === newWarehouseToProduct.id,
        );
        newDisplayStoragePlaces.push({
          ...newWarehouseToProduct,
          storagePlaces: storagePlacesOnWarehouse,
        });
      });

      setDisplayStoragePlaces(newDisplayStoragePlaces);
    }
  }, [loading, warehouses, storagePlaces]);

  const getStoragePlaceQuantity = (
    quantityStoragePlaceId: IStockQuantity["quantity"],
  ) => {
    if (!isFetchingStocksQuantityForProduct && stocksQuantityForProduct) {
      const quantities: IStockQuantity = stocksQuantityForProduct.find(
        (stock: IStockQuantity) =>
          stock.storagePlaceId === quantityStoragePlaceId,
      );
      if (quantities) {
        return parseInt(quantities.quantity, 10);
      }
      return 0;
    }
    return <CircularProgress color="inherit" size={15} />;
  };

  const getWarehouseQuantity = (warehouseId: IWarehouse["id"]) => {
    const storagePlacesOnWarehouse: Array<IStoragePlace> = [];
    warehouses.forEach((warehouse) => {
      if (warehouse.id === warehouseId && warehouse.storagePlaces) {
        storagePlacesOnWarehouse.push(...warehouse.storagePlaces);
      }
    });

    if (!isFetchingStocksQuantityForProduct && stocksQuantityForProduct) {
      let quantity = 0;
      storagePlacesOnWarehouse.forEach((storagePlaceOnWarehouse) => {
        quantity += getStoragePlaceQuantity(
          storagePlaceOnWarehouse.id,
        ) as number;
      });
      return quantity;
    }
    return <CircularProgress color="inherit" size={15} />;
  };

  const getInitialDate = () => {
    const initialDate = moment().toDate();
    initialDate.setSeconds(0);
    initialDate.setMilliseconds(0);
    return moment(initialDate);
  };

  const getAddStockInitialValues = () => ({
    actionDate: getInitialDate(),
    actionQuantity: 1,
  });

  const { mutate: addStockToAProduct } = useAddStockToAProduct();

  const { mutate: _useStockForProductAndStoragePlace } =
    useUseStockForProductAndStoragePlace();

  const addStockFormik = useFormik({
    initialValues: getAddStockInitialValues(),
    onSubmit: ({ actionDate, actionQuantity }, { setSubmitting }) => {
      addStockToAProduct(
        {
          productId,
          storagePlaceId: displayAddStockModal?.id || "",
          actionDate: actionDate.toISOString(),
          actionQuantity,
        },
        {
          onSuccess: () => {
            setDisplayAddStockModal(undefined);
            refetchStocksQuantityForProduct();
            enqueueSnackbar(
              `Ajout de ${actionQuantity} produit(s) au lieu de stockage "${displayAddStockModal?.name}".`,
              {
                variant: "success",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
          },
          onError: () => {
            enqueueSnackbar(
              `Erreur lors de l'ajout de ${actionQuantity} produit(s) au lieu de stockage "${displayAddStockModal?.name}".`,
              {
                variant: "error",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setSubmitting(false);
          },
        },
      );
    },
    validationSchema: yup.object().shape({
      actionQuantity: yup
        .number()
        .required("Le nombre est requis.")
        .positive("Le nombre doit être supérieur à 0.")
        .integer("Le nombre doit être un entier."),
    }),
  });

  const getUseStockInitialValues = ({
    warehouseId,
  }: {
    warehouseId?: IWarehouse["id"];
  }) => ({
    actionDate: getInitialDate(),
    actionQuantity: 1,
    warehouseId: warehouseId || null,
  });

  const useStockFormik = useFormik({
    initialValues: getUseStockInitialValues({}),
    onSubmit: ({ actionDate, actionQuantity }, { setSubmitting }) => {
      const newActionDate = moment(actionDate);
      const params = {
        actionDate: newActionDate,
        actionQuantity,
      };

      if (productHasOpeningDate) {
        newActionDate.startOf("day");
      }

      _useStockForProductAndStoragePlace(
        {
          productId,
          storagePlaceId: displayUseStockModal?.id || "",
          params,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `Utilisation de ${actionQuantity} produit(s) au lieu de stockage "${displayUseStockModal?.name}".`,
              {
                variant: "success",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setDisplayUseStockModal(undefined);
            refetchStocksQuantityForProduct();
            callbackAddUseStock();
          },
          onError: () => {
            enqueueSnackbar(
              `Erreur lors de l'utilisation de ${actionQuantity} produit(s) au lieu de stockage "${displayUseStockModal?.name}".`,
              {
                variant: "error",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setSubmitting(false);
          },
        },
      );
    },
    validationSchema: yup.object().shape({
      actionQuantity: yup
        .number()
        .required("La quantité est requise.")
        .positive("La quantité doit être supérieure à 0.")
        .integer("La quantité doit être un nombre.")
        .max(
          parseInt(
            getStoragePlaceQuantity(displayUseStockModal?.id || "").toString(),
          ),
          `La quantité doit être au maximum de ${getStoragePlaceQuantity(
            displayUseStockModal?.id || "",
          ).toString()}.`,
        ),
    }),
  });

  if (!loading && storagePlaces.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu de stockage lié au produit.</div>
        </div>
      </Paper>
    );
  }

  const handleCloseStockHistoryModal = () => {
    setIsOpenStockQuantityModal(false);
    setStoragePlaceForStockQuantityModal(undefined);
    setWarehouseForStockQuantityModal(undefined);
  };

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <StockHistoryModal
            open={isOpenStockQuantityModal}
            productId={productId}
            storagePlace={storagePlaceForStockQuantityModal}
            warehouse={warehouseForStockQuantityModal}
            handleClose={handleCloseStockHistoryModal}
          />
          <ConfirmationModalWithFormik
            key="add-stock-modal"
            open={!!displayAddStockModal}
            formik={addStockFormik}
            title={`Ajout de stock au lieu de stockage "${displayAddStockModal?.name}".`}
            handleClose={() => setDisplayAddStockModal(undefined)}
            yesText="Ajouter au stock"
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                value={addStockFormik.values.actionDate}
                onChange={(actionDate) => {
                  addStockFormik.setFieldValue("actionDate", actionDate);
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>

            <TextField
              label="Quantité"
              type="number"
              name="actionQuantity"
              inputProps={{ min: "1", step: "1" }}
              value={addStockFormik.values.actionQuantity}
              onChange={addStockFormik.handleChange}
              fullWidth
              className={classes.textField}
              helperText={
                addStockFormik.errors.actionQuantity &&
                addStockFormik.touched.actionQuantity &&
                addStockFormik.errors.actionQuantity
              }
              error={
                !!addStockFormik.errors.actionQuantity &&
                addStockFormik.touched.actionQuantity
              }
            />
          </ConfirmationModalWithFormik>
          {displayUseStockModal && (
            <ConfirmationModalWithFormik
              key="use-stock-modal"
              open={!!displayUseStockModal}
              formik={useStockFormik}
              title={`Utilisation du stock au lieu de stockage "${displayUseStockModal.name}" de l'entrepôt "${
                warehouses[
                  warehouses.findIndex(
                    (warehouse) =>
                      warehouse.id === useStockFormik.values.warehouseId,
                  )
                ].name
              }".`}
              handleClose={() => setDisplayUseStockModal(undefined)}
              yesText="Utiliser"
            >
              <Typography>
                Il reste actuellement{" "}
                <Pluralize
                  singular="produit"
                  count={getStoragePlaceQuantity(displayUseStockModal.id)}
                />
                .
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {productHasOpeningDate ? (
                  <DatePicker
                    label="Date d'ouverture"
                    value={moment(useStockFormik.values.actionDate)}
                    onChange={(actionDate) => {
                      useStockFormik.setFieldValue("actionDate", actionDate);
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                ) : (
                  <DateTimePicker
                    open={isOpenModalChooseUseDate}
                    ampm={false}
                    label="Date d'utilisation"
                    format="DD/MM/YYYY HH:mm"
                    value={useStockFormik.values.actionDate}
                    onChange={(actionDate) => {
                      useStockFormik.setFieldValue("actionDate", actionDate);
                    }}
                    // onClick={() => setIsOpenModalChooseUseDate(true)}
                    onClose={() => setIsOpenModalChooseUseDate(false)}
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    // fullWidth
                  />
                )}
              </LocalizationProvider>
              <TextField
                label="Quantité"
                type="number"
                name="actionQuantity"
                inputProps={{
                  min: "1",
                  step: "1",
                  max: getStoragePlaceQuantity(displayUseStockModal.id),
                }}
                value={useStockFormik.values.actionQuantity}
                onChange={useStockFormik.handleChange}
                fullWidth
                className={classes.textField}
                helperText={
                  useStockFormik.errors.actionQuantity &&
                  useStockFormik.touched.actionQuantity &&
                  useStockFormik.errors.actionQuantity
                }
                error={
                  !!useStockFormik.errors.actionQuantity &&
                  useStockFormik.touched.actionQuantity
                }
              />
            </ConfirmationModalWithFormik>
          )}
          <Title />
          {displayStoragePlaces &&
            displayStoragePlaces.map((warehouse: IWarehouse) => [
              <Grid
                key={warehouse.id}
                container
                className={classes.displayStoragePlaceContainer}
              >
                <Grid item xs={12}>
                  <Paper
                    className={classes.displayStoragePlacePaperContainer}
                    elevation={0}
                  >
                    <Badge
                      max={9999}
                      badgeContent={getWarehouseQuantity(warehouse.id)}
                      showZero
                      color="primary"
                      classes={{
                        root: classes.badgeRoot,
                      }}
                      onClick={() => {
                        setWarehouseForStockQuantityModal({
                          id: warehouse.id,
                          name: warehouse.name,
                        });
                        setIsOpenStockQuantityModal(true);
                      }}
                    >
                      <LinkUi
                        to={`/warehouses/${warehouse.id}`}
                        component={Link}
                        underline="none"
                      >
                        <div
                          className={
                            classes.linkGoToWarehouseOrStoragePlaceDetail
                          }
                        >
                          <Grid container>
                            <Grid
                              item
                              className={classes.warehouseImageWrapper}
                            >
                              <WarehouseImage
                                loaderSize={80}
                                maxWidth={150}
                                maxHeight={150}
                                rootStyle={classes.warehouseImage}
                                warehouseId={warehouse.id}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              className={classes.warehouseTitleContainer}
                            >
                              <Typography
                                className={classes.warehouseTitle}
                                variant="h4"
                              >
                                {warehouse.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </LinkUi>
                    </Badge>
                    <Divider />
                    <Grid
                      className={classes.storagePlacesOnWarehouseContainer}
                      container
                      spacing={5}
                    >
                      {warehouse.storagePlaces &&
                        warehouse.storagePlaces.map(
                          (storagePlaceElt: IStoragePlace) => (
                            <Grid
                              key={storagePlaceElt.id}
                              item
                              lg={4}
                              md={6}
                              xs={12}
                            >
                              <Paper
                                elevation={0}
                                classes={{ root: classes.paperStoragePlace }}
                              >
                                <Badge
                                  max={9999}
                                  badgeContent={getStoragePlaceQuantity(
                                    storagePlaceElt.id,
                                  )}
                                  showZero
                                  color="primary"
                                  classes={{
                                    root: classes.badgeRoot,
                                  }}
                                  onClick={() => {
                                    setStoragePlaceForStockQuantityModal({
                                      id: storagePlaceElt.id,
                                      name: storagePlaceElt.name,
                                    });
                                    setIsOpenStockQuantityModal(true);
                                  }}
                                >
                                  <LinkUi
                                    to={`/storage-places/${storagePlaceElt.id}`}
                                    component={Link}
                                    underline="none"
                                  >
                                    <div
                                      className={
                                        classes.linkGoToWarehouseOrStoragePlaceDetail
                                      }
                                    >
                                      <div
                                        className={
                                          classes.storagePlaceImageWrapper
                                        }
                                      >
                                        <StoragePlaceImage
                                          rootStyle={classes.storagePlaceImage}
                                          storagePlaceId={storagePlaceElt.id}
                                        />
                                      </div>
                                      <Typography
                                        className={classes.storagePlaceTitle}
                                        variant="h4"
                                      >
                                        {storagePlaceElt.name}
                                      </Typography>
                                    </div>
                                  </LinkUi>
                                </Badge>
                                <Divider />
                                <Grid
                                  className={
                                    classes.storagePlaceFooterContainer
                                  }
                                  container
                                  justifyContent="space-between"
                                  direction="row-reverse"
                                >
                                  <Button
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                      addStockFormik.resetForm();
                                      setDisplayAddStockModal(storagePlaceElt);
                                    }}
                                    disabled={
                                      isFetchingStocksQuantityForProduct
                                    }
                                  >
                                    Ajouter du stock
                                  </Button>
                                  <Button
                                    color="primary"
                                    size="small"
                                    startIcon={<RemoveShoppingCartIcon />}
                                    onClick={() => {
                                      useStockFormik.resetForm({
                                        values:
                                          getUseStockInitialValues(
                                            storagePlaceElt,
                                          ),
                                      });
                                      setDisplayUseStockModal(storagePlaceElt);
                                    }}
                                    disabled={
                                      isFetchingStocksQuantityForProduct ||
                                      parseInt(
                                        getStoragePlaceQuantity(
                                          storagePlaceElt.id,
                                        ).toString(),
                                      ) <= 0
                                    }
                                  >
                                    Utiliser du stock
                                  </Button>
                                </Grid>
                              </Paper>
                            </Grid>
                          ),
                        )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>,
            ])}
        </>
      )}
    </Paper>
  );
};

export default ProductStoragePlaces;
