import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link";
import { Link } from "react-router-dom";
// @ts-ignore
import Pluralize from "react-pluralize";
import CloseModal from "../Modals/CloseModal";
import { IWarehouse } from "types/warehouse.type";
import { IStoragePlace } from "src/types/storage-place.type";

interface IDeleteWarehouseStoragePlacesLinkedsModalProps {
  warehouse: IWarehouse;
  hancleClose: () => void;
  storagePlacesLinkeds: Array<IStoragePlace>;
}

const DeleteWarehouseStoragePlacesLinkedsModal = (
  props: IDeleteWarehouseStoragePlacesLinkedsModalProps,
) => {
  const { storagePlacesLinkeds, warehouse, hancleClose } = props;

  if (storagePlacesLinkeds.length === 0) {
    return "Aucun lieu de stockage lié à l'entrepôt.";
  }

  return (
    storagePlacesLinkeds.length > 0 && (
      <CloseModal
        key="delete-warehouse-confirmation-modal"
        open
        title={`Suppression d'un entrepôt.`}
        handleClose={hancleClose}
      >
        <Typography>{`Il n'est pas possible de supprimer l'entrepôt "${warehouse.name}".`}</Typography>
        <Typography>
          {`Il est lié à `}
          <Pluralize
            singular="lieux de stockage"
            count={storagePlacesLinkeds.length}
          />
          {` :`}
        </Typography>
        <ul>
          {storagePlacesLinkeds.map((storagePlaceLinked) => (
            <li key={storagePlaceLinked.id}>
              <LinkUi
                to={`/storage-places/${storagePlaceLinked.id}`}
                component={Link}
                underline="none"
              >
                {storagePlaceLinked.name}
              </LinkUi>
            </li>
          ))}
        </ul>
      </CloseModal>
    )
  );
};

export default DeleteWarehouseStoragePlacesLinkedsModal;
