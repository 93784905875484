import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import LinkUi from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import WarehouseIcon from "@mui/icons-material/List";
import Popover from "@mui/material/Popover";
// @ts-ignore
import Pluralize from "react-pluralize";
import { makeStyles } from "tss-react/mui";
import StoragePlaceImage from "./StoragePlaceImage";
import Search from "../Search";
import PageLoader from "../Loaders/PageLoader";
import ConfirmationModal from "../Modals/ConfirmationModal";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import StoragePlaceLinkedToProductsModal from "./StoragePlaceLinkedToProductsModal";
import {
  useDeleteStoragePlace,
  useStoragePlaces,
} from "../../Hooks/storage-places.hook";
import { Theme } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  rootPaper: {
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  squared: {
    borderRadius: 0,
  },
  outlined: {
    border: `1px solid ${theme.palette.background.default}`,
  },
  imageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  title: {
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "16px",
    height: theme.spacing(4),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paperFooter: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToStoragePlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  search: {
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  topContainer: {
    marginBottom: theme.spacing(4),
  },
  fullWidthForXs: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  deleteIcon: {
    cursor: "pointer",
  },
  skeletonLoader: {
    width: "30%",
    fontSize: theme.typography.fontSize,
  },
  verticalMiddle: {
    verticalAlign: "middle",
  },
  warehouseIcon: {
    marginRight: theme.spacing(1),
  },
  openPopover: {
    cursor: "pointer",
  },
}));

interface IStoragePlacesListingProps {
  key: string;
}

const StoragePlacesListing = (props: IStoragePlacesListingProps) => {
  const { key } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [storagePlaceToDelete, setStoragePlaceToDelete] =
    useState<IStoragePlace | null>(null);
  const [productsLinkeds, setProductsLinkeds] = useState([]);
  const [search, setSearch] = useState<string | boolean>(false);

  const {
    storagePlaces,
    isFetching,
    isError,
    refetch: refetchStoragePlaces,
  } = useStoragePlaces({
    join: "warehouse,products",
    filter: search ? `name||cont||${search}` : undefined,
  });

  const { mutate: deleteStoragePlace, isPending: isPendingDeleteStoragePlace } =
    useDeleteStoragePlace({
      onSuccess: () => {
        enqueueSnackbar(
          `Le lieu de stockage "${storagePlaceToDelete?.name}" a été supprimé.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        setStoragePlaceToDelete(null);
        refetchStoragePlaces();
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        const storagePlaceHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "STORAGE_PLACE_HAVE_PRODUCTS_LINKEDS";
        if (storagePlaceHaveProductsLinkeds) {
          setProductsLinkeds(data.title.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression du lieu de stockage "${storagePlaceToDelete?.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
          setStoragePlaceToDelete(null);
        }
      },
    });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des lieux de stockage.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    refetchStoragePlaces();
  }, [key]);

  const rootPaper = classNames({
    [classes.rootPaper]: true,
    [classes.squared]: classes.squared,
    [classes.outlined]: classes.outlined,
  });

  interface IProductsNamesProps {
    storagePlace: IStoragePlace;
  }

  const ProductsNames = (props: IProductsNamesProps) => {
    const { storagePlace } = props;

    const [anchorEl, setAnchorEl] = useState<
      React.SyntheticEvent["currentTarget"] | null
    >(null);

    const storagePlaceToDisplay = storagePlace;
    storagePlaceToDisplay.products = storagePlace?.products?.filter(
      (elt) => elt.deletedAt === null,
    );

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return [
      storagePlaceToDisplay?.products?.length === 0 && "Aucun produit",
      (storagePlaceToDisplay?.products?.length || 0) > 0 && (
        <Typography key="storage-place-open-popover">
          <LinkUi
            className={classes.openPopover}
            onClick={(event: React.SyntheticEvent) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <Pluralize
              singular="produit"
              zero="Aucun produit"
              count={storagePlaceToDisplay?.products?.length || 0}
            />
          </LinkUi>
        </Typography>
      ),
      <Popover
        key="storage-place-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {storagePlaceToDisplay?.products?.map((product) => (
          <Typography key={product.id}>
            <LinkUi component={Link} to={`/products/${product.id}`}>
              {product.name}
            </LinkUi>
          </Typography>
        ))}
      </Popover>,
    ];
  };

  const handleCloseModalDeleteStoragePlace = () => {
    if (!isPendingDeleteStoragePlace) {
      setStoragePlaceToDelete(null);
    }
  };

  return [
    productsLinkeds.length > 0 && storagePlaceToDelete && (
      <StoragePlaceLinkedToProductsModal
        key="storage-place-linked-to-product-modal"
        title={`Il n'est pas possible de supprimer le lieu de stockage "${storagePlaceToDelete.name}".`}
        productsLinkeds={productsLinkeds}
        storagePlace={storagePlaceToDelete}
        handleClose={() => {
          handleCloseModalDeleteStoragePlace();
          setProductsLinkeds([]);
        }}
        transitionDurationEnter={0}
      />
    ),
    productsLinkeds.length === 0 && storagePlaceToDelete && (
      <ConfirmationModal
        key="delete-storage-place-confirmation-modal"
        open
        loadingSuccessCallback={isPendingDeleteStoragePlace}
        title="Suppression d'un lieu de stockage."
        description={`Voulez-vous vraiment supprimer le lieu de stockage "${storagePlaceToDelete.name}" ?`}
        handleClose={handleCloseModalDeleteStoragePlace}
        yesCallback={() =>
          deleteStoragePlace({ storagePlaceId: storagePlaceToDelete.id })
        }
      />
    ),
    <Grid
      key="storage-place-listing-header"
      className={classes.topContainer}
      container
      spacing={8}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item className={classes.fullWidthForXs}>
        <Search
          key="search-container"
          className={classes.search}
          placeholder="Rechercher le nom d'un lieu de stockage"
          callback={(value) => setSearch(value)}
          disabled={isFetching || search ? false : storagePlaces.length <= 0}
        />
      </Grid>
      <Grid item className={classes.fullWidthForXs}>
        <Button
          className={classes.fullWidthForXs}
          variant="contained"
          color="secondary"
          onClick={() => navigate("/new-storage-place")}
        >
          Ajouter un lieu de stockage
        </Button>
      </Grid>
    </Grid>,
    !isFetching ? (
      <Grid key="listing-container" container spacing={24}>
        {storagePlaces && storagePlaces.length > 0 ? (
          storagePlaces.map((storagePlace: IStoragePlace) => (
            <Grid item key={storagePlace.id} lg={4} md={6} xs={12}>
              <Paper className={rootPaper} elevation={0}>
                <LinkUi
                  to={`/storage-places/${storagePlace.id}`}
                  component={Link}
                  underline="none"
                >
                  <div className={classes.linkGoToStoragePlaceDetail}>
                    <div className={classes.imageWrapper}>
                      <StoragePlaceImage
                        rootStyle={classes.image}
                        storagePlaceId={storagePlace.id}
                      />
                    </div>
                    <Typography className={classes.title} variant="h4">
                      {storagePlace.name}
                    </Typography>
                  </div>
                </LinkUi>
                <Divider />
                <Grid
                  className={classes.paperFooter}
                  container
                  justifyContent="space-between"
                >
                  <ProductsNames
                    key="products-names"
                    storagePlace={storagePlace}
                  />
                  <Typography>
                    <WarehouseIcon
                      className={`${classes.warehouseIcon} ${classes.verticalMiddle}`}
                    />
                    <LinkUi
                      className={classes.verticalMiddle}
                      component={Link}
                      to={`/warehouses/${storagePlace?.warehouse?.id || ""}`}
                    >
                      {storagePlace?.warehouse?.name || ""}
                    </LinkUi>
                  </Typography>
                  <DeleteIcon
                    className={classes.deleteIcon}
                    onClick={() => setStoragePlaceToDelete(storagePlace)}
                    color="action"
                  />
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid key="no-result" item container justifyContent="center" xs={12}>
            Aucun résultat.
          </Grid>
        )}
      </Grid>
    ) : (
      <div key="loader-container">
        <PageLoader />
      </div>
    ),
  ];
};

export default StoragePlacesListing;
