import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import LinkUi from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "tss-react/mui";
import WarehouseImage from "./WarehouseImage";
import Search from "../Search";
import PageLoader from "../Loaders/PageLoader";
import ConfirmationModal from "../Modals/ConfirmationModal";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import WarehouseStoragePlacesNames from "./WarehouseStoragePlacesNames";
import { useDeleteWarehouse, useWarehouses } from "../../Hooks/warehouses.hook";
import { IWarehouse } from "types/warehouse.type";
import { AxiosResponse } from "axios";
import { IStoragePlace } from "src/types/storage-place.type";
import DeleteWarehouseStoragePlacesLinkedsModal from "./DeleteWarehouseStoragePlacesLinkedsModal";

const useStyles = makeStyles()((theme) => ({
  rootPaper: {
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  squared: {
    borderRadius: 0,
  },
  outlined: {
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  imageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  title: {
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "16px",
    height: theme.spacing(4),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paperFooter: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToWarehouseDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  search: {
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  topContainer: {
    marginBottom: theme.spacing(4),
  },
  fullWidthForXs: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  deleteIcon: {
    cursor: "pointer",
  },
}));

interface IWarehousesListingProps {
  key: string;
}

function WarehousesListing(props: IWarehousesListingProps) {
  const { key } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [warehouseToDelete, setWarehouseToDelete] = useState<IWarehouse>();
  const [storagePlacesLinkedsDeleteModal, setStoragePlacesLinkedsDeleteModal] =
    useState<Array<IStoragePlace>>([]);
  const [search, setSearch] = useState<string | boolean>(false);

  const {
    warehouses,
    isFetching,
    isError,
    refetch: refetchWarehouses,
  } = useWarehouses({ filter: search ? `name||cont||${search}` : "" });

  const { mutate: deleteWarehouse, isPending: isPendingDeleteWarehouse } =
    useDeleteWarehouse({
      onSuccess: () => {
        enqueueSnackbar(
          `L'entrepôt "${warehouseToDelete?.name}" a été supprimé.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        setWarehouseToDelete(undefined);
        refetchWarehouses();
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const warehouseHaveStoragePlacesLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.storagePlacesLinkeds &&
          data.title.error === "WAREHOUSE_HAVE_STORAGE_PLACES_LINKEDS";
        if (warehouseHaveStoragePlacesLinkeds) {
          setStoragePlacesLinkedsDeleteModal(data.title.storagePlacesLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression de l'entrepôt "${warehouseToDelete?.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
          setWarehouseToDelete(undefined);
        }
      },
    });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    refetchWarehouses();
  }, [key]);

  const rootPaper = classNames({
    [classes.rootPaper]: true,
    [classes.squared]: classes.squared,
    [classes.outlined]: classes.outlined,
  });

  const listingComponent = useMemo(
    () =>
      !isFetching ? (
        <Grid key="listing-container" container spacing={24}>
          {warehouses && warehouses.length > 0 ? (
            warehouses.map((warehouse: IWarehouse) => (
              <Grid item key={warehouse.id} lg={4} md={6} xs={12}>
                <Paper className={rootPaper} elevation={0}>
                  <LinkUi
                    to={`/warehouses/${warehouse.id}`}
                    component={Link}
                    underline="none"
                  >
                    <div className={classes.linkGoToWarehouseDetail}>
                      <div className={classes.imageWrapper}>
                        <WarehouseImage
                          rootStyle={classes.image}
                          warehouseId={warehouse.id}
                        />
                      </div>
                      <Typography className={classes.title} variant="h4">
                        {warehouse.name}
                      </Typography>
                    </div>
                  </LinkUi>
                  <Divider />
                  <Grid
                    className={classes.paperFooter}
                    container
                    justifyContent="space-between"
                  >
                    <WarehouseStoragePlacesNames
                      key="warehouse-storage-places-names"
                      warehouse={warehouse}
                    />
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={() => setWarehouseToDelete(warehouse)}
                      color="action"
                    />
                  </Grid>
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid
              key="no-result"
              item
              container
              justifyContent="center"
              xs={12}
            >
              Aucun résultat.
            </Grid>
          )}
        </Grid>
      ) : (
        <div key="loader-container">
          <PageLoader />
        </div>
      ),

    [warehouses],
  );

  const handleCloseModalDeleteWarehouse = () => {
    if (!isPendingDeleteWarehouse) {
      setWarehouseToDelete(undefined);
    }
  };

  const hancleCloseDeleteWarehouseStoragePlacesLinkedsModal = () => {
    setWarehouseToDelete(undefined);
    setStoragePlacesLinkedsDeleteModal([]);
  };

  return [
    warehouseToDelete && storagePlacesLinkedsDeleteModal.length > 0 && (
      <DeleteWarehouseStoragePlacesLinkedsModal
        storagePlacesLinkeds={storagePlacesLinkedsDeleteModal}
        warehouse={warehouseToDelete}
        hancleClose={hancleCloseDeleteWarehouseStoragePlacesLinkedsModal}
      />
    ),
    storagePlacesLinkedsDeleteModal.length === 0 && warehouseToDelete && (
      <ConfirmationModal
        key="delete-warehouse-confirmation-modal"
        open
        loadingSuccessCallback={isPendingDeleteWarehouse}
        title="Suppression d'un entrepôt."
        description={`Voulez-vous vraiment supprimer l'entrepôt "${warehouseToDelete.name}" ?`}
        handleClose={handleCloseModalDeleteWarehouse}
        yesCallback={() =>
          deleteWarehouse({ warehouseId: warehouseToDelete.id })
        }
      />
    ),
    <Grid
      key="warehouse-listing-header"
      className={classes.topContainer}
      container
      spacing={8}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item className={classes.fullWidthForXs}>
        <Search
          key="search-container"
          className={classes.search}
          placeholder="Rechercher le nom d'un entrepôt"
          callback={(value) => setSearch(value)}
          disabled={isFetching || search ? false : warehouses.length <= 0}
        />
      </Grid>
      <Grid item className={classes.fullWidthForXs}>
        <Button
          className={classes.fullWidthForXs}
          variant="contained"
          color="secondary"
          onClick={() => navigate("/new-warehouse")}
        >
          Ajouter un entrepôt
        </Button>
      </Grid>
    </Grid>,
    listingComponent,
  ];
}

export default WarehousesListing;
