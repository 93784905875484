import { useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { useCloseStockForProduct } from "../../Hooks/stock.hook";
import { ICloseStockParams, IStockOpening } from "types/stock.type";

interface ICloseStockHistoryModalProps {
  open: boolean;
  handleClose: () => void;
  stockOpening?: IStockOpening;
}

const CloseStockHistoryModal = (props: ICloseStockHistoryModalProps) => {
  const { open, handleClose, stockOpening } = props;

  if (!stockOpening) return <></>;

  const { enqueueSnackbar } = useSnackbar();

  const getInitialDate = () => {
    const initialDate = moment().toDate();
    initialDate.setSeconds(0);
    initialDate.setMilliseconds(0);
    return moment(initialDate);
  };

  const [actionDate, setActionDate] = useState<moment.Moment>(getInitialDate());

  const {
    mutate: closeStockForProduct,
    isPending: isPendingCloseStockForProduct,
  } = useCloseStockForProduct({
    onSuccess: () => {
      enqueueSnackbar(
        `Fermeture du produit au lieu de stockage "${stockOpening.storagePlace.name}" de l'entrepôt "${stockOpening.warehouse.name}".`,
        {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
      handleClose();
    },
    onError: () => {
      enqueueSnackbar(
        `Erreur lors de la fermeture du produit au lieu de stockage "${stockOpening.storagePlace.name}" de l'entrepôt "${stockOpening.warehouse.name}".`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    },
  });

  const closeStock = () => {
    if (stockOpening) {
      const { id, productId, storagePlaceId } = stockOpening;
      const params: ICloseStockParams = {
        actionDate,
        stockOpeningId: id,
      };

      closeStockForProduct({
        productId,
        storagePlaceId,
        params,
      });
    }
  };

  return (
    <ConfirmationModal
      key="storage-place-stock-history-modal"
      open={open}
      title={
        open
          ? `Fermeture du stock du lieu de stockage ${stockOpening.storagePlace.name}`
          : ""
      }
      description={
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date de fermeture"
            value={actionDate}
            onChange={(newActionDate) => {
              setActionDate(newActionDate as moment.Moment);
            }}
            slotProps={{ textField: { fullWidth: true } }}
            minDate={moment(stockOpening.openingDate)}
            maxDate={moment()}
          />
        </LocalizationProvider>
      }
      loadingSuccessCallback={isPendingCloseStockForProduct}
      yesCallback={closeStock}
      handleClose={handleClose}
    />
  );
};

export default CloseStockHistoryModal;
